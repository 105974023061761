<template>
  <section class="rounded-[13px] mt-8 flex flex-col justify-center" v-if="products !== undefined && products.length > 0">
    <TitleItem class="ml-[15px]" :text="title"></TitleItem>
    <div v-if="!isProductsLoading" class="products-sample p-[14px] max-[800px]:p-[10px] max-[500px]:p-[5px] overflow-x-scroll max-w-full">
      <div class="products flex flex-row gap-[20px] max-[800px]:gap-[10px] max-[500px]:gap-[5px]">
        <product-card
          v-for="product in products"
          :key="product"
          :product="product"
        ></product-card>
      </div>
    </div>
    <LoadingMark v-else></LoadingMark>
  </section>
</template>


<script>
import axiosInstance from '@/const.js'
import ProductCard from '@/components/ProductCard'
import TitleItem from '@/components/TitleItem'
import LoadingMark from '@/components/UI/LoadingMark'

export default {
  components: {
    ProductCard,
    TitleItem,
    LoadingMark,
  },
  props: {
    title: String,
    path: String,
    inputProducts: null,
  },
  data() {
    return {
      products: [],
      isProductsLoading: false,
    }
  },
  methods: {
    async getProducts() {
      if (this.inputProducts) {
        this.products = this.inputProducts;
      } else {
        try {
          this.isProductsLoading = true;
          const response = await axiosInstance.get(
            `catalog/products/${this.path}/`
          )
          if (response.status !== 200) {
            throw new Error(`Status ${response.status}`);
          }
          this.products = response.data;
        } catch (e) {
          alert(e);
        } finally {
          this.isProductsLoading = false;
        }
      }
    }
  },
  mounted() {
    this.getProducts();
  }
}
</script>


<style lang="scss" scoped>
@import '@/assets/variables.scss';

section {
  background-color: $sample-background;
}
</style>