<template>
  <section class="catalog-products flex flex-col justify-center">
    <TitleItem text="Каталог товаров"></TitleItem>
    <div v-if="isMobile">
      <button @click="showSortingMenu" class="sort-mobile rounded-xl flex flex-row justify-center items-center gap-1.5 mb-3">
        <img src="@/assets/images/sorting.png" width="18" height="18" alt="sort"><span class="text-lg">Сортировка</span>
      </button>
    </div>
    <div v-else class="sort flex flex-row justify-start items-center mb-6 gap-[25px]">
      <button
        v-for="sorting of sortings"
        :key="sorting"
        @click="getProducts(sorting.name)"
        :class="{ active: sortedBy === sorting.name }"
      >{{ sorting.title }}</button>
    </div>
    <div>
      <div v-if="!isProductsLoading" class="flex flex-col justify-center items-center gap-[10px]">
        <div v-for="row in productsRows" :key="row" class="row w-full flex flex-row justify-start gap-[1.25%]">
          <ProductCardCatalog
            v-for="product in row"
            :key="product"
            :product="product"
          ></ProductCardCatalog>
        </div>
      </div>
      <LoadingMark v-else></LoadingMark>
      <!-- <h2 v-else>Не удалось загрузить каталог</h2> -->
      <!-- <aside class="filters flex flex-col justify-start">
        <form @submit.prevent="getProducts(this.sortedBy)">
          <div class="filters__categories">
            <h3>Категории</h3>
            <div>
              <CategoryCheckbox
                v-for="category in categories"
                :key="category"
                :categoryID="category.id"
                :categoryName="category.name"
              ></CategoryCheckbox>
            </div>
          </div>
          <div class="filters__price">
            <h3>Цена</h3>
            <PriceInput placeholder="От"></PriceInput>
            <PriceInput placeholder="До"></PriceInput>
          </div>
          <button type="submit">Применить</button>
        </form>
      </aside> -->
    </div>
    <div v-if="!isSortingHidden" @click="hideSortingMenu" class="background-dark absolute z-10 inset-y-0 inset-x-0"></div>
    <div v-if="!isSortingHidden" class="sorting-mobile-menu flex flex-col justify-center items-center gap-3 rounded-t-2xl absolute bottom-0 inset-x-0 z-20">
      <p class="text-[28px]">Показать сначала</p>
      <div class="sorting-mobile-menu__container rounded-lg flex flex-col justify-around px-5">
        <div v-for="sorting in sortings" :key="sorting" class="sorting-radio flex flex-row justify-start gap-3">
          <input
            type="radio"
            :id="sorting.name"
            :value="sorting.name"
            @change="getProducts(sorting.name)"
            v-model="sortedBy"
          >
          <label :for="sorting.name" class="text-[25px]">{{ sorting.title }}</label>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import axiosInstance from '@/const.js'
import ProductCardCatalog from '@/components/ProductCardCatalog'
import TitleItem from '@/components/TitleItem'
import CategoryCheckbox from '@/components/UI'
import PriceInput from '@/components/UI'
import LoadingMark from '@/components/UI'

export default {
  components: {
    ProductCardCatalog,
    CategoryCheckbox,
    TitleItem,
    LoadingMark,
    PriceInput,
  },
  data() {
    return {
      products: [],
      productsRows: [],
      isProductsLoading: false,
      isCategoriesLoading: false,
      rowLength: 5,
      categories: [],
      selectedCategories: [],
      minPrice: 0,
      maxPrice: null,
      sortedBy: 'alphabet',
      isMobile: false,
      isSortingHidden: true,
      sortings: [
        {
          name: 'alphabet',
          title: 'по алфавиту',
        },
        {
          name: 'cheaper',
          title: 'подешевле',
        },
        {
          name: 'expensive',
          title: 'подороже',
        },
        {
          name: 'new',
          title: 'по новизне',
        },
      ],
    }
  },
  methods: {
    async getProducts(sorted) {
      try {
        this.isSortingHidden = true;
        document.body.style.overflowY = 'visible';
        this.sortedBy = sorted;
        this.products = [];
        this.productsRows = [];
        this.isProductsLoading = true;
        const response = await axiosInstance.get(
          `catalog/products/?sortedBy=${sorted}`,
          {
            params: {
              category: this.selectedCategories[0],
              minPrice: this.minPrice,
              maxPrice: this.maxPrice,
            }
          }
        )
        if (response.status !== 200) {
          throw new Error(`Status ${response.status}`);
        }
        this.products = response.data;
        let currentRow = [];
        if (window.innerWidth <= 900) {
          this.rowLength = 3;
        }
        for (let product of response.data) {
          if (currentRow.length < this.rowLength) {
            currentRow.push(product);
          } else {
            this.productsRows.push(currentRow);
            currentRow = [product];
          }
        }
        this.productsRows.push(currentRow);
      } catch (e) {
        alert(e);
      } finally {
        this.isProductsLoading = false;
      }
    },
    async getCategories() {
      try {
        this.isCategoriesLoading = true;
        const response = await axiosInstance.get('catalog/categories/');
        if (response.status !== 200) {
          throw new Error(`Status ${response.status}`);
        }
        for (let category of response.data) {
          this.categories.push(
            {
              id: category.id,
              name: category.name,
            }
          )
        }
      } catch (e) {
        alert(e);
      } finally {
        this.isCategoriesLoading = false;
      }
    },
    setTypeScreen() {
      if (window.innerWidth <= 900) {
        this.isMobile = true;
      }
    },
    showSortingMenu() {
      document.body.style.overflowY = 'hidden';
      this.isSortingHidden = false;
    },
    hideSortingMenu() {
      document.body.style.overflowY = 'visible';
      this.isSortingHidden = true;
    },
  },
  mounted() {
    this.setTypeScreen();
    this.selectedCategories.push(this.$route.params.id);
    this.getProducts(this.sortedBy);
    this.getCategories();
  }
}
</script>

<style lang="scss">
@import '@/assets/variables.scss';

.sort {
  button {
    color: #A4A19F;
    font-size: 25px;
  }

  button.active {
    color: black;
    font-size: 25px;
  }
}

.sort-mobile {
  width: 135px;
  height: 32px;
  background-color: $beige;
}

.sorting-mobile-menu {
  background-color: white;
  width: 100vw;
  height: 30vh;
}

.sorting-mobile-menu__container {
  background-color: #FFF4EF;
  width: 90%;
  height: 70%;
}

.sorting-radio label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 25px;
  margin-right: 0;
  line-height: 18px;
  user-select: none;
}

.sorting-radio input[type=radio] {
  display: none;
}

.sorting-radio label:before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  position: absolute;
  left: 0;
  bottom: 1px;
  background: url("/src/assets/images/radio.png") 0 0 no-repeat;
}

.sorting-radio input[type=radio]:checked + label:before {
  background: url("/src/assets/images/checked.png") 0 0 no-repeat;
}

.background-dark {
  background-color: black;
  opacity: 0.38;
  width: 100vw;
  height: 100vh;
}
</style>
