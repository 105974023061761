import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import AboutView from '@/views/AboutView.vue'
import CatalogView from '@/views/CatalogView.vue'
import CatalogProductsView from '@/views/CatalogProductsView.vue'
import BlogView from '@/views/BlogView.vue'
import ProductView from '@/views/ProductView.vue'
// import AdminHomeView from '@/views/AdminHomeView.vue'
// import AdminLoginView from '@/views/AdminLoginView.vue'
// import AdminProductsView from '@/views/AdminProductsView.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
  },
  {
    path: '/catalog',
    name: 'catalog',
    component: CatalogView,
  },
  {
    path: '/catalog/category/:id/:name',
    name: 'catalog-by-category',
    component: CatalogProductsView,
  },
  {
    path: "/blog",
    name: 'blog',
    component: BlogView,
  },
  {
    path: '/products/:article',
    name: 'products',
    component: ProductView,
  },
  // {
  //   path: '/admin-login',
  //   name: 'admin_login',
  //   component: AdminLoginView,
  //   meta: { requiresAuth: false }
  // },
  // {
  //   path: '/admin',
  //   name: 'admin',
  //   component: AdminHomeView,
  //   meta: { requiresAuth: true }
  // },
  // {
  //   path: '/admin/products',
  //   name: 'admin_products',
  //   component: AdminProductsView,
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo({top: 0});
  next();
})

export default router;
