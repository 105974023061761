<template>
  <router-link
    :to="{ name: 'products', params: { article: product.id } }"
    class="product-card flex flex-col justify-start rounded-[13px] no-underline gap-[10px] max-[600px]:gap-[5px]"
  >
    <div
      class="product-card__image rounded-[13px] w-full bg-cover bg-center bg-no-repeat"
      :style="{ backgroundImage: `url(${product.main_photo})` }"
    ></div>
    <div class="prodcut-card__description h-[30%] flex flex-col justify-between items-start">
      <h5 class="font-bold text-start lg:hover:underline text-card-title leading-tight">{{ validateName() }}</h5>
      <h4 class="text-start text-price-size"><strong>{{ validatePrice() }} &#8381;</strong></h4>
    </div>
  </router-link>
</template>


<script>
export default {
  props: {
    product: Object,
  },
  methods: {
    validateName() {
      const subString = this.product.name.substring(0, 43);
      return subString < this.product.name ? `${subString}...` : this.product.name;
    },
    validatePrice() {
      return this.product.price.substring(0, this.product.price.length - 3);
    },
  },
}
</script>


<style lang="scss" scoped>
@import '@/assets/variables.scss';

.product-card {
  width: 19%;
  aspect-ratio: 24 / 35;

  @media (max-width: 900px) {
    width: 32%;
  }
}

.product-card__image {
  width: 100%;
  aspect-ratio: 1 / 1;
}

.prodcut-card__description {
  h5 {
    color: #000000;

    @media (max-width: 900px) {
      font-size: 16px;
    }

    @media (max-width: 760px) {
      font-size: 14px;
    }

    @media (max-width: 600px) {
      font-size: 10px;
    }
  }

  h4 {
    color: $price-color;

    @media (max-width: 900px) {
      font-size: 20px;
    }

    @media (max-width: 760px) {
      font-size: 18px;
    }

    @media (max-width: 600px) {
      font-size: 12px;
    }
  }
}
</style>