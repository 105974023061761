<template>
  <header>
    <header-info class="py-10px"></header-info>
    <section class="header-menu">
      <div class="header-menu__container">
        <nav>
          <router-link to="/catalog" class="nav-item catalog font-normal lg:hover:underline"><strong>Каталог товаров</strong></router-link>
          <router-link to="/" class="nav-item font-normal lg:hover:underline"><strong>Главная</strong></router-link>
          <router-link to="/blog" class="nav-item font-normal lg:hover:underline">Полезные статьи</router-link>
          <router-link to="/about" class="nav-item font-normal lg:hover:underline">О нас</router-link>
        </nav>
        <!-- <div class="search">
          <form @submit.prevent="search">
            <input type="text" placeholder="Что вы хотите найти?">
            <button type="submit">Поиск</button>
          </form>
        </div> -->
      </div>
    </section>
  </header>
</template>

<script>
import HeaderInfo from './HeaderInfo.vue'

export default {
  components: {
    HeaderInfo,
  },
  methods: {
    search() {
      // TODO: сделать поиск
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

header {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 2px solid $beige;
  margin-bottom: 20px;
}

.header-menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px;
  max-width: 100%;
}

.header-menu__container {
  display: flex;
  flex-direction: row;
  max-width: 1440px;
  width: 100%;
  justify-content: center;
}

nav {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 0 50px;

  @media (max-width: 700px) {
    padding: 0;
  }
}

.nav-item {
  text-decoration: none;
  font-size: $nav-menu-desktop;
  color: $text-dark;

  @media (max-width: 550px) {
    font-size: $nav-menu-tablet;
  }

  @media (max-width: 400px) {
    font-size: $nav-menu-mobile;
  }
}

.catalog {
  display: block;
  background-color: $orange-pink;
  padding: 2px 10px;
  border-radius: 5px;
}

.search {
  width: 50vw;
}
</style>